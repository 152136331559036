import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { environment } from "../../environments/environment";
import { ToastController } from '@ionic/angular';
import { DataSyncService } from './data-sync.service';

@Injectable({
    providedIn: 'root'
})
export class SecurityService {

    private env: any;

    constructor(
        private http: HttpClient,
        private cookie: CookieService,
        private toast: ToastController,
        private router: Router,
        private syncDataService: DataSyncService,
    ) { this.env = environment; }

    public signin(data:any) {
        let api = `${this.env.api}/signin`;
        return this.http.post(api, data);
    }

    public singout() {
        let api = `${this.env.api}/signout`;
        return this.http.post(api, {});
    }

    /**
     * Check if session is valid.
     * @param route string
     * @returns boolean
     */
    public verifySession(router:string = '/signin') {
        let cookie = this.cookie.get('Authorization');
        if(!cookie) {
            this.syncDataService.$hideMenu.emit(true);
            this.syncDataService.$profile.emit(undefined);
            if(router != '/signin' && router != '/')
                this.showToast('danger', 'User unauthorized.')
            this.router.navigate(['/signin']);
            return false;
        }
        return true;
    }

    private async showToast(color:string, message:string) {
        const toast = await this.toast.create({
            message: message,
            duration: 2000,
            position: 'top',
            color: color,
            buttons: [
                {
                  icon: 'close',
                  htmlAttributes: {
                    'aria-label': 'close',
                  },
                },
              ],
          });

          await toast.present();
    }

}
