// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // api: 'http://localhost:9000',
    // url: 'http://localhost:4200'
    api: 'https://sib.kodev.io/api/dev',
    url: 'https://sib.kodev.io'
    // api: 'https://pkf.kodev.io/api/prod',
    // url: 'https://pkf.kodev.io'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
export const entityDocumentsForm = {
    lucrativaA: {
        title: "Sociedades Mercantiles",
        description: "",
        creation: {
            subtitle: "Datos de la escritura pública de constitución del cliente",
            numero: "Número de escritura",
            fecha: "Fecha de la escritura",
            notario: "Notario autorizante",
        },
        modification: {
            subtitle: "Modificaciones a la escritura publica de constitución del cliente",
            numero: "Número de escritura",
            fecha: "Fecha",
            notario: "Notario autorizante",
            tipoModificacion: "Tipo de modificación",
        },
        patenteSociedad: {
            subtitle: "Datos del Registro (patente de sociedad)",
            numero: "Número",
            folio: "Folio",
            libro: "Libro"
        }
    },
    lucrativaB: {
        title: "Documento de creación",
        description: "Indique la información del Acuerdo Gubernativo o ducmento similar de creación",
        creation: {
            subtitle: "Datos del documento de creación",
            establecimientoInformacion: "",
            numero: "Número documento de creación",
            fecha: "Fecha documento de creación",
            notario: "Nombre completo de quien autoriza el documento de creación (cuando aplique)",
        },
        modification: {
            subtitle: "Datos del documento de creación",
            numero: "Número documento de modificación",
            fecha: "Fecha documento de modificación",
            notario: "Nombre completo de quien autoriza el documento de modificación",
            tipoModificacion: "Tipo de modificación",
        },
        patenteSociedad: {
            subtitle: "Datos del Registro",
            numero: "Número",
            folio: "Folio",
            libro: "Libro"
        }
    }
}
