import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DataSyncService {

    $loading = new EventEmitter<any>();
    $hideMenu = new EventEmitter<any>();
    $profile = new EventEmitter<any>();

    $datosSolicitante = new EventEmitter<any>();
    // $datosRepresentante = new EventEmitter<any>();
    // $solicitanteLugar = new EventEmitter<any>();
    // $solicitanteFecha = new EventEmitter<any>();
    // $solicitanteZona = new EventEmitter<any>();

    // fix representanteLegal
    $representanteLegal = new EventEmitter<any>();
    // fix representanteLegal

    // $datosInscripcionRepLegal = new EventEmitter<any>();
    $datosIdentificacion = new EventEmitter<any>();  // eliminar
    // $identificacionTipoEntidad = new EventEmitter<any>();

    $solicitanteCpe = new EventEmitter<any>();

    $entidadSolicitante = new EventEmitter<any>(); // checked
    $informacionEconomica = new EventEmitter<any>(); // checked
    $productosServicios = new EventEmitter<any>();

    constructor() { }
}
