import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

    constructor(
        private cookieService: CookieService,
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        let token = this.cookieService.get('Authorization');
        if(!request.url.includes('signin') && !request.url.includes('signup')) {
            request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
        }

        return next.handle(request);
    }
}

export const HttpInterceptorProviders = [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpRequestInterceptor,
    multi: true,
}];
