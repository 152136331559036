import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DataSyncService } from './services/data-sync.service';
import { SecurityService } from "./services/security.service";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

    public hideMenu:boolean;
    public profile:any;

    public appPages = [
        { title: 'Juridico', url: '/legal', icon: 'receipt' },
        { title: 'Clientes', url: '/client', icon: 'person' },
        { title: 'Salir', url: '/signout', icon: 'exit' },
        // { title: 'Archived', url: '/folder/archived', icon: 'archive' },
        // { title: 'Trash', url: '/folder/trash', icon: 'trash' },
        // { title: 'Spam', url: '/folder/spam', icon: 'warning' },
    ];
    public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
    constructor(
        private router: Router,
        private cookieService: CookieService,
        private syncDataService: DataSyncService,
        private securityService: SecurityService,
    ) {
        this.hideMenu = false;
    }

    ngOnInit(): void {
        this.syncDataService.$profile.subscribe((value:any) => { this.profile = value });
        this.syncDataService.$hideMenu.subscribe((value:any) => { this.hideMenu = value });
        // if(this.securityService.verifySession('/')) {
            // this.profile = localStorage.getItem('profile');
            // this.hideMenu = false;
            // this.router.navigate(['/legal']);
        // } else {
            // this.cookieService.delete('Authorization');
            // this.syncDataService.$hideMenu.emit(true);

        // }

    }
}
